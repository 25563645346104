import RainbowLink from '@otovo/shared/components/RainbowLink/RainbowLink';
import ArrowDownCircleSVG from '@otovo/shared/images/icons/arrow-up-right.svg';
import { Otovo$Locale } from '@otovo/shared/types/otovoweb';
import { Sanity$RelatedProductsSection } from '@otovo/shared/types/sanityTypes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { getLocalizedPathRoot } from '../../../lib/getLocalizedPathRoot';
import CustomHeading from './CustomHeading';

type Props = {
  content: Sanity$RelatedProductsSection;
};

const RelatedProducts = ({ content }: Props) => {
  const { locale } = useRouter() as { locale: Otovo$Locale };
  const localizedRoute = getLocalizedPathRoot('productPage', locale);
  const headingStyles = 'mb-4 text-2xl font-bold';
  return (
    <div className="bg-white">
      <div className=" m-auto max-w-7xl p-8">
        <div className="grid grid-cols-8 items-center rounded-xl md:gap-20 md:bg-lilac-5 md:p-20">
          <div className="col-span-full md:col-span-3">
            <CustomHeading
              heading={content.heading}
              headingStyles={headingStyles}
            />
            {content.productPages.map((productPage) => (
              <RainbowLink
                locale={locale}
                key={productPage._key}
                href={`/${localizedRoute}/${productPage.slug}`}
                className="flex items-center justify-between gap-10 border-t border-grey-10 py-6 text-[18px] font-light text-grey-60 md:text-lg"
              >
                {productPage.title}
                <div className="h-max w-max rounded-full bg-peach-50 p-3 text-grey-90">
                  <ArrowDownCircleSVG />
                </div>
              </RainbowLink>
            ))}
          </div>

          <Image
            src="/img/solar_powered_house.png"
            width={674}
            height={340}
            alt="solar powered house"
            className="col-span-full mt-10 w-full max-w-full md:col-span-5 md:mt-0 "
          />
        </div>
      </div>
    </div>
  );
};
export default RelatedProducts;
